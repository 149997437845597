import React, { useState } from 'react';
import AdoptechModal from '../../../../../../../components/AdoptechModal/AdoptechModal';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../../../components/AdoptechButton/AdoptechButton';
import {
  AssertionModelStatusEnum,
  AssertionsAssertionIdCreateReviewPostRequest,
} from '../../../../../../../swagger';
import './EditTestFormReviewTestModal.scss';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { AdoptechTextArea2 } from '../../../../../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import classNames from 'classnames';
import { DropZone } from '../../../../../../../components/DropZone/DropZone';
import { useSelector } from 'react-redux';
import { postAssertionReview } from '../../../../../../../store/compliance/complianceThunks';
import { ApplicationState } from '../../../../../../../types/applicationState';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { useUpdateAssertionEverywhere } from '../EditTestFormLastResultsSection/EditTestFormLastResultsList/EditTestFormLastResultsList';
import { EditTestFormSection } from '../EditTestForm';
import { selectCurrentVendorUser } from '../../../../../../../selectors/selectCurrentVendorUser';

interface EditTestFormReviewTestModalProps extends EditTestFormSection {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
}

export const EditTestFormReviewTestModal: React.FC<
  EditTestFormReviewTestModalProps
> = props => {
  const baseCss = 'reviewTestModal';
  const childClass = (name: string) => `${baseCss}--${name}`;
  const title = 'Review test';
  const [reviewStatus, setReviewStatus] =
    useState<AssertionModelStatusEnum>(null);

  const [reviewComment, setReviewComment] = useState<string>(null);
  const [reviewEvidences, setReviewEvidences] = useState<File[]>([]);
  const dispatch = useAppDispatch();
  const updateAssertionEveryWhere = useUpdateAssertionEverywhere();

  const handleConfirm = async () => {
    const params: AssertionsAssertionIdCreateReviewPostRequest = {
      assertionId: props.formData.id,
      assertionReviewStatus: reviewStatus,
      assertionReviewComment: reviewComment,
    };

    if (reviewEvidences) {
      params.assertionReviewEvidences = reviewEvidences;
    }

    const assertion = await dispatch(postAssertionReview(params)).unwrap();
    const { status, latestRunResults, overdue, nextRunAfter } = assertion;

    const assertionPayload = {
      status,
      latestRunResults,
      overdue,
      nextRunAfter,
    };
    updateAssertionEveryWhere(assertionPayload, props);

    props.onConfirm();
    props.onHide();
  };
  const handleStatusClick = (status: AssertionModelStatusEnum) =>
    setReviewStatus(status);
  const isSubmitting =
    useSelector(
      (state: ApplicationState) => state.compliance.postAssertionReviewStatus
    ) === 'loading';
  const handleFileChange = (files: File[]) => setReviewEvidences(files);

  const submitButtonDisabled = !reviewStatus || !reviewComment;
  const currentVendorUser = useSelector(selectCurrentVendorUser);
  return (
    <AdoptechModal
      show={props.show}
      onHide={props.onHide}
      className={childClass('modal')}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body className={childClass('body')}>
        <div className={childClass('statusButtons')}>
          <AdoptechButton
            icon={faCheck}
            size="middle2"
            extraClass={classNames({
              'adoptechButton-withGreenIcon': true,
              active: reviewStatus === AssertionModelStatusEnum.Compliant,
            })}
            onClick={() =>
              handleStatusClick(AssertionModelStatusEnum.Compliant)
            }
          >
            Mark as passed
          </AdoptechButton>
          <AdoptechButton
            icon={faXmark}
            size="middle2"
            extraClass={classNames({
              'adoptechButton-withRedIcon': true,
              active: reviewStatus === AssertionModelStatusEnum.Failed,
            })}
            onClick={() => handleStatusClick(AssertionModelStatusEnum.Failed)}
          >
            Mark as failed
          </AdoptechButton>
        </div>
        <div className={childClass('commentTextarea')}>
          <AdoptechTextArea2
            value={reviewComment}
            rows={2}
            label="Type review comments"
            id="testReviewComment"
            rounded
            hasError={!reviewComment}
            onChange={event => setReviewComment(event.currentTarget.value)}
          />
        </div>
        <div>
          <div className={childClass('uploadEvidenceLabel')}>
            Upload evidence
          </div>
          <div className={childClass('upload-area')}>
            <DropZone
              smallHeight
              dottedBorder
              multiple
              onChange={handleFileChange}
              placeholderFileListText="Uploaded evidence"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={childClass('footer')}>
        <AdoptechButton
          variant={AdoptechButtonVariant.White}
          onClick={props.onHide}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          variant={AdoptechButtonVariant.Primary}
          onClick={handleConfirm}
          disabled={submitButtonDisabled}
          busy={isSubmitting}
        >
          Confirm
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
