import {
  ComplianceEditControlFormModel,
  ComplianceFilterPayload,
  DrawerType,
} from '../../components/compliance/Types/complianceTypes';
import { ControlPageFilterStatus } from '../../features/compliance/controls/Controls/ControlsHeader';
import { MessageToastVariant } from '../../components/MessageToast/MessageToast';

import {
  ControlCategoryExtended,
  ControlExtended,
  ControlModelStatusEnum,
  DataRoomEntityModel,
  EvidenceModel,
  FrameworkGuidanceModel,
  FrameworkModel,
  FrameworkRequirementCategoryExtended,
  FrameworkRequirementCategoryModel,
  TaskExtended,
  TaskList,
  TaskModel,
  VendorDocument,
} from '../../swagger';
import { ApiRequestStatus } from '../global/globalState';

export enum EditControlMode {
  Normal,
  MarkNotApplicable,
  View, // used in audits answer for evidence button
}

export enum EditTaskMode {
  Normal,
  View, // used in audits answer for evidence button
}

export interface ControlsFilters {
  frameworkIdentifier?: string;
  assigneeId?: string;
  status?: ControlPageFilterStatus;
  applicable?: string;
}

export interface ComplianceState {
  assignableTasks: TaskModel[];
  categories?: ControlCategoryExtended[];
  controls?: ControlExtended[];
  controlsSearch?: string;
  drawerOnTop: DrawerType;
  fetchFrameworksError?: string;
  fetchCategoriesError?: string;
  fetchTasksError?: string;
  deleteEvidenceError?: string;
  fetchEvidenceDownloadDetailsError?: string;
  postTaskEvidenceError?: string;
  frameworks: FrameworkModel[];
  isFetchingAssignableTasks: boolean;
  isCreatingControl: boolean;
  isPatchingControl: boolean;
  frameworkPolicies: VendorDocument[];
  frameworkRequirementCategories: FrameworkRequirementCategoryExtended[];
  frameworkGuidances: Record<string, FrameworkGuidanceModel>;
  isFetchingFrameworkPolicies: boolean;
  isFetchingFrameworkRequirements: boolean;
  isFetchingFrameworks: boolean;
  isFetchingCategories: boolean;
  isFetchingTaskDetails: boolean;
  isFetchingEvidenceDownloadDetails: boolean;
  isFetchingTasks: boolean;
  isPatchingTask: boolean;
  isPostingTask: boolean;
  isFetchingControlDetails: boolean;
  isPostingTaskEvidence: boolean;
  isDeletingEvidence: boolean;
  isPDFReportRequest: boolean;
  isPDFReportError: string;
  selectedFrameworkId: string; // can be null, no localStorage
  selectedCategoryId: string;
  selectedControlId: string;
  selectedTaskId: string;
  editControlFormModel: ComplianceEditControlFormModel;
  tasks: TaskModel[];
  tasksMeta: TaskList['meta'];
  tempTask: TaskExtended;
  showControlDrawer: boolean;
  showTaskDrawer: boolean;
  toastMessage: string;
  notApplicableControls: ControlExtended[];
  isFetchingNotApplicableControls: boolean;
  fetchNotApplicableControlsError: string;
  tasksFilters: ComplianceFilterPayload;
  categoriesFilters: ComplianceFilterPayload;
  toastVariant: MessageToastVariant;
  controlIdBeingMarkedNotApplicable?: string;
  isMarkingControlApplicable: boolean;
  isMarkingControlNotApplicable: boolean;
  markingControlApplicableError?: string;
  markingControlNotApplicableError?: string;
  editControlMode: EditControlMode;
  isPostFrameworkRequest: boolean;
  isPostFrameworkError: string;
  editTaskMode: EditTaskMode;
  isSettingInitialTempTaskFields: boolean; // deprecated, owner not used
  controlsFilters: ControlsFilters;
  isPostingReviewHistoryItem: boolean;
  isFetchingReviewHistoryItems: boolean;
  patchControlCheckAssertionsStatus: ApiRequestStatus;
  fetchAssertionDetailsStatus: ApiRequestStatus;
  fetchAssertionDetailsSilentStatus: ApiRequestStatus;
  patchAssertionDetailsStatus: ApiRequestStatus;
  postAssertionRunStatus: ApiRequestStatus;
  patchAssertionResultExcludeStatus: ApiRequestStatus;
  patchAssertionResultIncludeStatus: ApiRequestStatus;
  postAssertionReviewStatus: ApiRequestStatus;
  postFrameworkEvidenceExportStatus: ApiRequestStatus;
  fetchEvidenceStatus: ApiRequestStatus;
  evidence: DataRoomEntityModel[];
  fetchControlsStatus: ApiRequestStatus;
  removeVendorRequirementStatus: ApiRequestStatus;
}
