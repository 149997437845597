import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import './FrameworkRequirementCategories.scss';
import { AdoptechAccordionCard } from '../../AdoptechAccordionCard/AdoptechAccordionCard';
import { Accordion } from 'react-bootstrap';
import {
  FrameworkRequirementCategoryExtended,
  FrameworkRequirementExtended,
  FrameworkRequirementExtendedStatusEnum,
  FrameworkRequirementModelTypeEnum,
} from '../../../swagger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import {
  createVendorRequirement,
  removeVendorRequirement,
} from '../../../store/compliance/complianceThunks';
import { FrameworkGuidance } from './FrameworkGuidence';

interface Props {
  categories: FrameworkRequirementCategoryExtended[];
}

const baseCss = 'frameworkRequirementCategories';
export const FrameworkRequirementCategories: React.FC<Props> = ({
  categories,
}) => {
  const categoryTitle = (category: FrameworkRequirementCategoryExtended) => {
    const completed = category.frameworkRequirements.every(
      r => r.status == FrameworkRequirementExtendedStatusEnum.Completed
    );
    const requirementsCount = category.frameworkRequirements.length;
    const requirementsCompleted = category.frameworkRequirements.filter(
      r => r.status == FrameworkRequirementExtendedStatusEnum.Completed
    ).length;
    return (
      <div className={`${baseCss}--categoryHeader`}>
        <FontAwesomeIcon
          icon={completed ? faCheckCircle : faCircle}
          className={
            completed ? baseCss + '--greenTick' : baseCss + '--greyTick'
          }
        />
        <div className={`${baseCss}--title`}>{category.name}</div>
        <div className={`${baseCss}--categoryCompleted`}>
          {requirementsCompleted}/{requirementsCount} Completed
        </div>
      </div>
    );
  };

  return (
    <Accordion className={baseCss}>
      {categories.map(category => (
        <AdoptechAccordionCard
          key={category.id}
          index={category.identifier}
          title={categoryTitle(category)}
          cardBodyClass={`${baseCss}--category-body`}
        >
          <Accordion>
            {category.frameworkRequirements.map(requirement => (
              <FrameworkRequirementCard
                key={requirement.id}
                requirement={requirement}
              />
            ))}
          </Accordion>
        </AdoptechAccordionCard>
      ))}
    </Accordion>
  );
};
interface FrameworkRequirementCardProps {
  requirement: FrameworkRequirementExtended;
}

const FrameworkRequirementCard: React.FC<FrameworkRequirementCardProps> = ({
  requirement,
}) => {
  const [openedGuidance, setOpenedGuidance] = useState('');
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState(false);

  const completeHandler = async () => {
    setIsBusy(true);
    await dispatch(createVendorRequirement(requirement.id));
    setIsBusy(false);
  };
  const isCompleted =
    requirement.status == FrameworkRequirementExtendedStatusEnum.Completed;

  const removeHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    const answerId = requirement.vendorFrameworkRequirementId;

    if (isCompleted && answerId) {
      setIsBusy(true);
      dispatch(removeVendorRequirement(answerId));
      setIsBusy(false);
    }
  };

  const requirementTitle = () => {
    return (
      <div className={`${baseCss}--header`}>
        <FontAwesomeIcon
          icon={isCompleted ? faCheckCircle : faCircle}
          className={
            isCompleted ? baseCss + '--greenTick' : baseCss + '--greyTick'
          }
          onClick={isCompleted ? removeHandler : undefined}
        />
        <span className={`${baseCss}--title`}>{requirement.name}</span>
      </div>
    );
  };

  return (
    <AdoptechAccordionCard
      key={requirement.id}
      index={requirement.identifier}
      title={requirementTitle()}
    >
      <div
        className={`${baseCss}--description`}
        dangerouslySetInnerHTML={{
          __html: requirement.descriptionAsHtml,
        }}
      />
      <div className={`${baseCss}--actions`}>
        {requirement.type == FrameworkRequirementModelTypeEnum.Manual &&
          (requirement.status ==
          FrameworkRequirementExtendedStatusEnum.Pending ? (
            <AdoptechButton
              variant={AdoptechButtonVariant.White}
              extraClass={`${baseCss}--manual`}
              onClick={completeHandler}
              busy={isBusy}
            >
              Mark as completed
            </AdoptechButton>
          ) : (
            <AdoptechButton
              variant={AdoptechButtonVariant.SuccessTransparent}
              extraClass={`${baseCss}--manual`}
              onClick={removeHandler}
              busy={isBusy}
            >
              Completed
            </AdoptechButton>
          ))}
        {requirement.type == FrameworkRequirementModelTypeEnum.SendMessage &&
          (requirement.status ==
          FrameworkRequirementExtendedStatusEnum.Pending ? (
            <AdoptechButton
              variant={AdoptechButtonVariant.White}
              extraClass={`${baseCss}--send-message`}
              onClick={completeHandler}
              busy={isBusy}
            >
              Request meeting
            </AdoptechButton>
          ) : (
            <AdoptechButton
              disabled
              variant={AdoptechButtonVariant.SuccessTransparent}
              extraClass={`${baseCss}--send-message`}
              busy={isBusy}
              onClick={removeHandler}
            >
              Meeting requested
            </AdoptechButton>
          ))}
        {requirement.guidanceIdentifier &&
          (requirement.guidanceIdentifier != openedGuidance ? (
            <div>
              <AdoptechButton
                variant={AdoptechButtonVariant.White}
                extraClass={`${baseCss}--send-message`}
                onClick={() =>
                  setOpenedGuidance(requirement.guidanceIdentifier)
                }
              >
                Show Guidance
              </AdoptechButton>
            </div>
          ) : (
            <div>
              <AdoptechButton
                variant={AdoptechButtonVariant.White}
                extraClass={`${baseCss}--send-message`}
                onClick={() => setOpenedGuidance('')}
              >
                Hide Guidance
              </AdoptechButton>
            </div>
          ))}
      </div>
      {openedGuidance && requirement.guidanceIdentifier == openedGuidance && (
        <FrameworkGuidance
          guidanceIdentifier={requirement.guidanceIdentifier}
        />
      )}
    </AdoptechAccordionCard>
  );
};
