import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ComplianceEditControlFormModel,
  ComplianceFilterPayload,
  DrawerType,
} from '../../components/compliance/Types/complianceTypes';
import { MessageToastVariant } from '../../components/MessageToast/MessageToast';
import {
  ControlCategoryExtended,
  ControlExtended,
  ControlModel,
  ControlModelStatusEnum,
  EvidenceModel,
  FrameworkGuidanceModel,
  FrameworkModel,
  FrameworkRequirementCategoryExtended,
  FrameworkRequirementExtendedStatusEnum,
  FrameworkRequirementModel,
  ReviewStatusEnum,
  TaskExtended,
  TaskList,
  TaskModel,
  TaskModelStatusEnum,
  VendorDocument,
  VendorFrameworkRequirementModel,
} from '../../swagger';
import { NOT_FOUND_INDEX } from '../../types/constants';
import {
  ComplianceState,
  ControlsFilters,
  EditControlMode,
  EditTaskMode,
} from './complianceState';
import {
  fetchAssertionDetails,
  fetchAssertionDetailsSilent,
  fetchComplianceControls,
  fetchEvidence,
  patchAssertionDetails,
  patchAssertionResultExclude,
  patchAssertionResultInclude,
  patchControlCheckAssertions,
  postAssertionReview,
  postAssertionRun,
  postFrameworkEvidenceExport,
  removeVendorRequirement,
} from './complianceThunks';
import { ControlPageFilterStatus } from '../../features/compliance/controls/Controls/ControlsHeader';

const updateEditControlFormModelTasks = (
  state: ComplianceState,
  payloadTasks: TaskModel[]
) => {
  // Merge tasks, not replace.
  // Example: Add a check to control ( not saved yet ) -> click view check -> save check changes ( status to compliant for example ) -> return to this screen ->
  // we must see "updated" added task which has not been saved yet to control
  state.editControlFormModel.tasks = state.editControlFormModel.tasks.map(
    formTask => {
      const updatedTask = payloadTasks.find(task => task.id === formTask.id);

      return updatedTask
        ? {
            ...formTask,
            ...updatedTask,
          }
        : formTask;
    }
  );
  const tasksIds = state.editControlFormModel.tasks.map(task => task.id);

  // Insert tasks which were not updated ( For example: Open control RHS without expanding it from meatball menu )
  payloadTasks.map(payloadTask => {
    if (!tasksIds.includes(payloadTask.id))
      state.editControlFormModel.tasks.push(payloadTask);
  });
};

export const controlIsCompliant = (tasks: TaskModel[]) => {
  return !(
    (tasks || []).length === 0 ||
    tasks.some(
      task =>
        task.status === TaskModelStatusEnum.Pending ||
        task.status === TaskModelStatusEnum.Failed
    )
  );
};

export const controlIsFailed = (tasks: TaskModel[]) => {
  return tasks.some(task => task.status === TaskModelStatusEnum.Failed);
};

interface ControlExtendedWithIndex extends ControlExtended {
  orderIndex?: number;
}

const initialState: ComplianceState = {
  categories: [],
  assignableTasks: [],
  controls: [],
  controlsSearch: undefined,
  notApplicableControls: [],
  tasks: [],
  tasksMeta: {},
  fetchCategoriesError: '',
  fetchTasksError: '',
  frameworks: [],
  isFetchingAssignableTasks: false,
  isCreatingControl: false,
  frameworkPolicies: [],
  frameworkRequirementCategories: [],
  frameworkGuidances: {},
  isFetchingFrameworkPolicies: false,
  isFetchingFrameworkRequirements: false,
  isFetchingFrameworks: false,
  isFetchingCategories: false,
  isFetchingTasks: false,
  isFetchingTaskDetails: false,
  selectedFrameworkId: undefined,
  selectedCategoryId: undefined,
  selectedControlId: undefined,
  selectedTaskId: undefined,
  showControlDrawer: false,
  isFetchingControlDetails: false,
  showTaskDrawer: false,
  editControlFormModel: {} as ComplianceEditControlFormModel,
  toastMessage: '',
  tasksFilters: {},
  categoriesFilters: {},
  editControlMode: EditControlMode.Normal,
  editTaskMode: EditTaskMode.Normal,
  controlsFilters: {} as ControlsFilters,
  patchControlCheckAssertionsStatus: 'idle',
  tempTask: null as TaskExtended,
  fetchAssertionDetailsStatus: 'idle',
  patchAssertionDetailsStatus: 'idle',
  postAssertionRunStatus: 'idle',
  patchAssertionResultIncludeStatus: 'idle',
  patchAssertionResultExcludeStatus: 'idle',
  fetchEvidenceStatus: 'idle',
  drawerOnTop: null,
  isPatchingControl: false,
  isFetchingEvidenceDownloadDetails: false,
  isPatchingTask: false,
  isPostingTask: false,
  isPostingTaskEvidence: false,
  isDeletingEvidence: false,
  isPDFReportRequest: false,
  isPDFReportError: '',
  isFetchingNotApplicableControls: false,
  fetchNotApplicableControlsError: '',
  toastVariant: null,
  isMarkingControlApplicable: false,
  isMarkingControlNotApplicable: false,
  isPostFrameworkRequest: false,
  isPostFrameworkError: '',
  isSettingInitialTempTaskFields: false,
  isPostingReviewHistoryItem: false,
  isFetchingReviewHistoryItems: false,
  fetchAssertionDetailsSilentStatus: 'idle',
  postAssertionReviewStatus: 'idle',
  postFrameworkEvidenceExportStatus: 'idle',
  evidence: [],
  fetchControlsStatus: 'idle',
  removeVendorRequirementStatus: 'idle',
};
const complianceSlice = createSlice({
  initialState,
  name: 'complianceSlice',
  reducers: {
    cleanupCompliance: state => {
      Object.assign(state, initialState);
    },
    clearToastMessage: state => {
      state.toastMessage = '';
      state.toastVariant = MessageToastVariant.Primary;
    },
    createControlRequest: state => {
      state.isCreatingControl = true;
      state.toastMessage = '';
    },
    createControlSuccess: (state, action: PayloadAction<ControlExtended>) => {
      state.isCreatingControl = false;
      state.controls.push(action.payload);

      const catIndex = state.categories.findIndex(cat =>
        state.editControlFormModel.frameworksRelations.find(
          fr => fr.controlCategory.id === cat.id
        )
      );

      if (catIndex > NOT_FOUND_INDEX)
        state.categories[catIndex].controls.push(action.payload);
    },
    createControlError: (state, action: PayloadAction<string>) => {
      state.toastMessage = action.payload;
      state.toastVariant = MessageToastVariant.Warning;
      state.isCreatingControl = false;
    },
    fetchAssignableComplianceTasksRequest: state => {
      state.isFetchingAssignableTasks = true;
    },
    fetchAssignableComplianceTasksSuccess: (
      state,
      action: PayloadAction<TaskModel[]>
    ) => {
      state.isFetchingAssignableTasks = false;
      state.assignableTasks = action.payload;
    },
    fetchRequirementsSuccess: (
      state,
      action: PayloadAction<FrameworkRequirementCategoryExtended[]>
    ) => {
      state.isFetchingFrameworkRequirements = false;
      state.frameworkRequirementCategories = action.payload;
    },
    fetchFrameworkGuidanceSuccess: (
      state,
      action: PayloadAction<[string, FrameworkGuidanceModel]>
    ) => {
      state.frameworkGuidances[action.payload[0]] = action.payload[1];
    },
    createVendorRequirementSuccess: (
      state,
      action: PayloadAction<VendorFrameworkRequirementModel>
    ) => {
      state.isFetchingFrameworkRequirements = false;
      state.frameworkRequirementCategories.forEach(category => {
        category.frameworkRequirements.forEach(requirement => {
          if (requirement.id == action.payload.frameworkRequirementId) {
            requirement.vendorFrameworkRequirementId = action.payload.id;
            requirement.status =
              FrameworkRequirementExtendedStatusEnum.Completed;
          }
        });
      });
    },
    fetchAssignableComplianceTasksError: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isFetchingAssignableTasks = false;
      state.toastMessage = action.payload;
      state.toastVariant = MessageToastVariant.Warning;
    },
    fetchRequirementsRequest: state => {
      state.isFetchingCategories = true;
    },
    fetchCategoriesRequest: state => {
      state.isFetchingCategories = true;
    },
    setTasksFilters: (
      state,
      action: PayloadAction<ComplianceFilterPayload>
    ) => {
      state.tasksFilters = { ...state.tasksFilters, ...action.payload };
    },
    setCategoriesFilters: (
      state,
      action: PayloadAction<ComplianceFilterPayload>
    ) => {
      state.categoriesFilters = { assigneeId: action.payload?.assigneeId };
    },
    fetchCategoriesSuccess: (
      state,
      action: PayloadAction<ControlCategoryExtended[]>
    ) => {
      const categories = action.payload;
      state.categories = categories;
      const notApplicable: ControlExtended[] = [];

      categories.forEach(category => {
        const applicable: ControlExtended[] = [];
        category.controls.forEach(control =>
          (control.applicable ? applicable : notApplicable).push(control)
        );
        category.controls = applicable;
      });

      state.notApplicableControls = notApplicable;
      state.isFetchingCategories = false;
      state.selectedCategoryId = categories ? categories[0].id : undefined;
    },
    fetchCategoriesError: (state, action: PayloadAction<string>) => {
      state.isFetchingCategories = false;
      state.fetchCategoriesError = action.payload;
    },
    fetchNotApplicableControlsError: (state, action: PayloadAction<string>) => {
      state.fetchNotApplicableControlsError = action.payload;
      state.isFetchingNotApplicableControls = false;
    },
    fetchNotApplicableControlsRequest: state => {
      state.fetchNotApplicableControlsError = undefined;
      state.isFetchingNotApplicableControls = true;
    },
    fetchNotApplicableControlsSuccess: (
      state,
      action: PayloadAction<ControlExtended[]>
    ) => {
      state.isFetchingNotApplicableControls = false;
      state.notApplicableControls = action.payload;
    },
    fetchFrameworkPoliciesRequest: state => {
      state.isFetchingFrameworkPolicies = true;
    },
    fetchFrameworkPoliciesSuccess: (
      state,
      action: PayloadAction<VendorDocument[]>
    ) => {
      state.isFetchingFrameworkPolicies = false;
      state.frameworkPolicies = action.payload;
    },
    fetchFrameworkPoliciesError: (state, action: PayloadAction<string>) => {
      state.isFetchingFrameworkPolicies = false;
      // TODO: handle the error
    },
    fetchFrameworksRequest: state => {
      state.isFetchingFrameworks = true;
    },
    fetchFrameworksSuccess: (
      state,
      action: PayloadAction<FrameworkModel[]>
    ) => {
      state.isFetchingFrameworks = false;
      state.frameworks = action.payload.sort((a, b) =>
        a.rowOrder > b.rowOrder ? 1 : -1
      );
    },
    fetchFrameworksError: (state, action: PayloadAction<string>) => {
      state.isFetchingFrameworks = false;
      state.fetchFrameworksError = action.payload;
    },
    fetchTaskDetailsRequest: state => {
      state.isFetchingTaskDetails = true;
    },
    cleanupTaskDetails: state => {
      state.tempTask = undefined;
      state.selectedTaskId = undefined;
    },
    fetchTaskDetailsSuccess: state => {
      state.isFetchingTaskDetails = false;
    },
    setTempTaskInitialFieldsRequest: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSettingInitialTempTaskFields = action.payload;
    },
    fetchTaskDetailsError: (state, action: PayloadAction<string>) => {
      state.isFetchingTaskDetails = false;
      state.fetchTasksError = action.payload;
      state.tempTask = undefined;
    },
    fetchTasksRequest: state => {
      state.isFetchingTasks = true;
    },
    fetchTasksSuccess: (state, action: PayloadAction<TaskList>) => {
      state.isFetchingTasks = false;
      state.tasks = action.payload['tasks'];
      state.tasksMeta = action.payload['meta'];
    },

    updateTasksInTheEditControlFormSlice: (
      state,
      action: PayloadAction<TaskModel>
    ) => {
      if (!state.editControlFormModel.tasks) return;
      updateEditControlFormModelTasks(state, [action.payload]);
    },
    updateControlReviewStatusSuccess: (
      state,
      action: PayloadAction<{
        id: ControlModel['id'];
        nextReviewDate: string;
        reviewStatus: ReviewStatusEnum;
        reviewOverdue: false;
      }>
    ) => {
      const { id, nextReviewDate, reviewStatus, reviewOverdue } =
        action.payload;
      const control = state.controls.find(c => c.id == id);
      control.reviewStatus = reviewStatus;
      control.nextReviewDate = nextReviewDate;
      control.reviewOverdue = reviewOverdue;
      if (state.editControlFormModel.id === id) {
        state.editControlFormModel.reviewStatus = reviewStatus;
        state.editControlFormModel.nextReviewDate = nextReviewDate;
        state.editControlFormModel.reviewOverdue = reviewOverdue;
      }

      state.categories.map(category => {
        category.controls = category.controls.map(categoryControl => {
          if (categoryControl.id === id) {
            categoryControl.reviewStatus = reviewStatus;
            categoryControl.nextReviewDate = nextReviewDate;
            categoryControl.reviewOverdue = reviewOverdue;
          }

          return categoryControl;
        });
        category;
      });
    },
    fetchControlExtendedRequest: state => {
      state.isFetchingControlDetails = true;
    },
    fetchControlExtendedSuccess: (
      state,
      action: PayloadAction<ControlExtended>
    ) => {
      state.isFetchingControlDetails = false;
      const {
        id,
        tasks,
        policies,
        status,
        reviewStatus,
        lastReview,
        custom,
        assignee,
        vendorPolicies,
        risks,
        helpArticleUrl,
        access,
      } = action.payload;
      const control = state.controls.find(c => c.id == id);
      if (control) {
        control.tasks = tasks;
        control.policies = policies;
        control.vendorPolicies = vendorPolicies;
        control.risks = risks;
        control.reviewStatus = reviewStatus;
        control.status = status;
        control.lastReview = lastReview;
        control.custom = custom;
        control.access = access;
        control.helpArticleUrl = helpArticleUrl;
      }

      if (state.editControlFormModel.id === id) {
        state.editControlFormModel.policies = policies;
        state.editControlFormModel.vendorPolicies = vendorPolicies;
        state.editControlFormModel.status = status;
        state.editControlFormModel.reviewStatus = reviewStatus;
        state.editControlFormModel.lastReview = lastReview;
        state.editControlFormModel.custom = custom;
        state.editControlFormModel.assignee = assignee;
        state.editControlFormModel.access = access;
        state.editControlFormModel.helpArticleUrl = helpArticleUrl;
        state.editControlFormModel.risks = risks;

        updateEditControlFormModelTasks(state, action.payload.tasks);
      }
    },

    fetchControlExtendedError: (state, action: PayloadAction<string>) => {
      state.isFetchingControlDetails = false;
    },

    fetchTasksError: (state, action: PayloadAction<string>) => {
      state.isFetchingTasks = false;
      state.fetchTasksError = action.payload;
    },

    setTempTask: (state, action: PayloadAction<TaskModel>) => {
      state.tempTask = action.payload;
    },

    postTaskEvidenceRequest: state => {
      state.isPostingTaskEvidence = true;
    },

    postTaskEvidenceSuccess: (state, action: PayloadAction<EvidenceModel>) => {
      state.isPostingTaskEvidence = false;
      state.tempTask.evidences.push(action.payload);
    },

    fetchEvidenceDownloadDetailsRequest: state => {
      state.isFetchingEvidenceDownloadDetails = true;
    },

    fetchEvidenceDownloadDetailsSuccess: state => {
      state.isFetchingEvidenceDownloadDetails = false;
    },

    fetchEvidenceDownloadDetailsError: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isFetchingEvidenceDownloadDetails = false;
      state.fetchEvidenceDownloadDetailsError = action.payload;
    },

    deleteEvidenceRequest: state => {
      state.isDeletingEvidence = true;
    },

    deleteEvidenceSuccess: (state, action: PayloadAction<string>) => {
      state.isDeletingEvidence = false;
      state.tempTask.evidences = state.tempTask.evidences.filter(
        evidence => evidence.id !== action.payload
      );
    },

    deleteEvidenceError: (state, action: PayloadAction<string>) => {
      state.isDeletingEvidence = false;
      state.deleteEvidenceError = action.payload;
    },

    createPDFReportRequest: state => {
      state.isPDFReportRequest = true;
    },

    createPDFReportSuccess: (state, action: PayloadAction<string>) => {
      state.isPDFReportRequest = false;
    },

    createPDFReportError: (state, action: PayloadAction<string>) => {
      state.isPDFReportRequest = false;
      state.isPDFReportError = action.payload;
    },

    postTaskEvidenceError: (state, action: PayloadAction<string>) => {
      state.isPostingTaskEvidence = false;
      state.postTaskEvidenceError = action.payload;
    },

    postTaskRequest: state => {
      state.isPostingTask = true;
    },

    postTaskSuccess: (state, action: PayloadAction<TaskModel>) => {
      state.isPostingTask = false;
      state.tasks = [action.payload, ...state.tasks];
    },
    postTaskError: (state, action: PayloadAction<string>) => {
      state.isPostingTask = false;
      state.fetchTasksError = action.payload;
    },
    patchTaskRequest: state => {
      state.isPatchingTask = true;
    },

    // update control status in tempTask.controls[0].status
    updateHiddenEditTaskDrawerControls: state => {
      const taskDrawerControls = state.tempTask?.controls;
      if (taskDrawerControls) {
        state.tempTask.controls = state.tempTask?.controls.map(control => {
          const newStatus = (state.controls || []).find(
            treeControl => treeControl.id === control.id
          )?.status;
          return {
            ...control,
            ...{ status: newStatus },
          };
        });
      }
    },

    updateTaskInTheTree: (
      state,
      action: PayloadAction<Partial<TaskModel> & { partialUpdate?: boolean }>
    ) => {
      const payload = action.payload;
      // partialUpdate false by default
      const { id, partialUpdate, ...payloadFields } = payload;

      state.tasks = state.tasks.map(task =>
        task.id === id
          ? partialUpdate
            ? { ...task, ...payloadFields }
            : (payload as TaskModel)
          : task
      );

      state.controls = state.controls.map(control => {
        if (control.tasks) {
          const newTasks = control.tasks.map(task =>
            task.id === id
              ? partialUpdate
                ? { ...task, ...payloadFields }
                : (payload as TaskModel)
              : task
          );

          const controlCompliant = controlIsCompliant(newTasks);

          const newStatus = controlCompliant
            ? ControlModelStatusEnum.Compliant
            : controlIsFailed(newTasks)
              ? ControlModelStatusEnum.Failed
              : ControlModelStatusEnum.Pending;

          return {
            ...control,
            tasks: newTasks,
            status: newStatus,
          };
        }

        return control;
      });

      state.categories = state.categories.map(category => ({
        ...category,
        controls: category.controls.map(control => {
          const newTasks = control.tasks?.map(task =>
            task.id === id
              ? partialUpdate
                ? { ...task, ...payloadFields }
                : (payload as TaskModel)
              : task
          );

          const controlCompliant = controlIsCompliant(newTasks);

          const newStatus = controlCompliant
            ? ControlModelStatusEnum.Compliant
            : controlIsFailed(newTasks)
              ? ControlModelStatusEnum.Failed
              : ControlModelStatusEnum.Pending;

          return {
            ...control,
            tasks: newTasks,
            status: newStatus,
          };
        }),
      }));
    },

    patchTaskSuccess: (state, action: PayloadAction<TaskModel>) => {
      state.isPatchingTask = false;
    },

    patchTaskError: (state, action: PayloadAction<string>) => {
      state.isPatchingTask = false;
      state.fetchTasksError = action.payload;
    },
    patchControlRequest: state => {
      state.isPatchingControl = true;
      state.toastMessage = '';
    },
    patchControlSuccess: (state, action: PayloadAction<ControlExtended>) => {
      state.isPatchingControl = false;
      state.showControlDrawer = false;

      const index = state.controls.findIndex(c => c.id === action.payload.id);
      if (index > NOT_FOUND_INDEX) state.controls[index] = action.payload;

      let controlIndex = NOT_FOUND_INDEX;
      let categoryIndex = NOT_FOUND_INDEX;
      const found = false;

      for (const cat of state.categories) {
        categoryIndex++;
        controlIndex = cat.controls.findIndex(
          con => con.id === action.payload?.id
        );
        if (controlIndex > NOT_FOUND_INDEX) break;
      }

      if (categoryIndex > NOT_FOUND_INDEX && controlIndex > NOT_FOUND_INDEX)
        state.categories[categoryIndex].controls[controlIndex] = action.payload;

      // if edit test opened
      if (state.tempTask && !state.tempTask.assignee) {
        state.tempTask.assignee = action.payload.assignee;
      }
    },
    patchControlError: (state, action: PayloadAction<string>) => {
      state.isPatchingControl = false;
      state.toastMessage = action.payload;
      state.toastVariant = MessageToastVariant.Warning;
      state.showControlDrawer = false;
    },
    removeControlRelation: (state, action: PayloadAction<string>) => {
      state.tempTask?.controls?.splice(
        state.tempTask?.controls?.findIndex(tc => tc.id === action.payload),
        1
      );
    },
    setControlFormModel: (
      state,
      action: PayloadAction<ComplianceEditControlFormModel>
    ) => {
      state.editControlFormModel = action.payload;
    },
    setControlFormModelValid: (state, action: PayloadAction<boolean>) => {
      state.editControlFormModel.formValid = action.payload;
    },
    setSelectedFramework: (state, action: PayloadAction<FrameworkModel>) => {
      state.selectedFrameworkId = action.payload?.id;
    },
    setSelectedFrameworkId: (
      state,
      action: PayloadAction<FrameworkModel['id']>
    ) => {
      state.selectedFrameworkId = action.payload;
    },
    setSelectedCategoryId: (state, action: PayloadAction<string>) => {
      state.selectedCategoryId = action.payload;
    },
    setSelectedControlId: (state, action: PayloadAction<string>) => {
      state.selectedControlId = action.payload;
    },
    setSelectedTaskId: (state, action: PayloadAction<string>) => {
      state.selectedTaskId = action.payload;
    },
    setShowControlDrawer: (state, action: PayloadAction<boolean>) => {
      state.showControlDrawer = action.payload;
      if (action.payload) {
        state.drawerOnTop = DrawerType.Control;
      } else {
        state.editControlMode = EditControlMode.Normal;
      }
    },
    setShowTaskDrawer: (state, action: PayloadAction<boolean>) => {
      state.showTaskDrawer = action.payload;
      if (action.payload) state.drawerOnTop = DrawerType.Task;
    },
    setDrawerOnTop: (state, action: PayloadAction<DrawerType>) => {
      state.drawerOnTop = action.payload;
    },
    markControlApplicableFailure: (state, action: PayloadAction<string>) => {
      state.isMarkingControlApplicable = false;
      state.markingControlApplicableError = action.payload;
    },
    markControlApplicableRequest: state => {
      state.isMarkingControlApplicable = true;
      state.markingControlApplicableError = undefined;
    },
    markControlApplicableSuccess: state => {
      state.isMarkingControlApplicable = false;
    },
    markControlNotApplicableFailure: (state, action: PayloadAction<string>) => {
      state.isMarkingControlNotApplicable = false;
      state.markingControlNotApplicableError = action.payload;
    },
    markControlNotApplicableRequest: (state, action: PayloadAction<string>) => {
      state.controlIdBeingMarkedNotApplicable = action.payload;
      state.isMarkingControlNotApplicable = true;
      state.markingControlNotApplicableError = undefined;
    },
    markControlNotApplicableSuccess: (
      state,
      action: PayloadAction<ControlModel>
    ) => {
      state.isMarkingControlNotApplicable = false;
      // save reasonForExclusion from state.controls to state.notApplicableControls
      state.notApplicableControls = state.notApplicableControls.map(control => {
        if (control.id !== action.payload.id) return control;
        return { ...control, ...action.payload };
      });
    },
    removeApplicableControl: (
      state,
      action: PayloadAction<{ categoryId: string; controlId: string }>
    ) => {
      const category = state.categories.find(
        c => c.id === action.payload.categoryId
      );
      if (!category) return;
      category.controls = category.controls.filter(
        c => c.id !== action.payload.controlId
      );
    },
    removeNotApplicableControl: (
      state,
      action: PayloadAction<{ controlId: string }>
    ) => {
      state.notApplicableControls = state.notApplicableControls.filter(
        nac => nac.id !== action.payload.controlId
      );
    },
    addNotApplicableControl: (
      state,
      action: PayloadAction<ControlExtended>
    ) => {
      state.notApplicableControls.push(action.payload);
    },
    addApplicableControl: (state, action: PayloadAction<ControlExtended>) => {
      if (!state.selectedFrameworkId) {
        console.error('state.selectedFrameworkId is empty');
      }
      const categoryId = action.payload.frameworksRelations.find(
        fr => fr.framework.id === state.selectedFrameworkId
      ).controlCategory.id;
      const category = state.categories.find(c => c.id === categoryId);
      if (!category) return;

      // orderIndex - optional temporary field to add control in valid order
      // if control was marked as not applicable but clicked cancel without saving reason for exclusion
      // TODO: may be we need to have this index on the backend
      const { orderIndex } = action.payload as ControlExtendedWithIndex;
      if (orderIndex) {
        category.controls.splice(orderIndex, 0, action.payload);
      } else {
        category.controls.push(action.payload);
      }
    },
    setEditControlMode: (state, action: PayloadAction<EditControlMode>) => {
      state.editControlMode = action.payload;
    },
    setEditTaskMode: (state, action: PayloadAction<EditTaskMode>) => {
      state.editTaskMode = action.payload;
    },

    postFrameworkRequest: state => {
      state.isPostFrameworkRequest = true;
    },
    postFrameworkSuccess: (state, action: PayloadAction<string>) => {
      state.isPostFrameworkRequest = false;
    },
    postFrameworkError: (state, action: PayloadAction<string>) => {
      state.isPostFrameworkRequest = false;
      state.isPostFrameworkError = action.payload;
    },
    setControlsFilterAssigneeId: (state, action: PayloadAction<string>) => {
      state.controlsFilters.assigneeId = action.payload;
    },
    setControlsFilterFrameworkIdentifier: (
      state,
      action: PayloadAction<string>
    ) => {
      state.controlsFilters.frameworkIdentifier = action.payload;
    },
    setControlsFilterStatus: (
      state,
      action: PayloadAction<ControlPageFilterStatus>
    ) => {
      state.controlsFilters.status = action.payload;
    },
    setControlsFilterApplicable: (state, action: PayloadAction<string>) => {
      state.controlsFilters.applicable = action.payload;
    },
    setControlsSearch: (state, action: PayloadAction<string>) => {
      state.controlsSearch = action.payload;
    },
    isPostingReviewHistoryItemRequest: state => {
      state.isPostingReviewHistoryItem = true;
    },
    isPostingReviewHistoryItemSuccess: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isPostingReviewHistoryItem = false;
    },
    isPostingReviewHistoryItemFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isPostingReviewHistoryItem = false;
    },

    isFetchingReviewHistoryItemsRequest: state => {
      state.isFetchingReviewHistoryItems = true;
    },
    isFetchingReviewHistoryItemsSuccess: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isFetchingReviewHistoryItems = false;
    },
    isFetchingReviewHistoryItemsFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isFetchingReviewHistoryItems = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(patchControlCheckAssertions.pending, state => {
        state.patchControlCheckAssertionsStatus = 'loading';
      })
      .addCase(patchControlCheckAssertions.fulfilled, (state, action) => {
        state.patchControlCheckAssertionsStatus = 'succeeded';
      })
      .addCase(patchControlCheckAssertions.rejected, state => {
        state.patchControlCheckAssertionsStatus = 'failed';
      })
      .addCase(fetchAssertionDetails.pending, state => {
        state.fetchAssertionDetailsStatus = 'loading';
      })
      .addCase(fetchAssertionDetails.fulfilled, (state, action) => {
        state.fetchAssertionDetailsStatus = 'succeeded';
      })
      .addCase(fetchAssertionDetails.rejected, state => {
        state.fetchAssertionDetailsStatus = 'failed';
      })
      .addCase(fetchAssertionDetailsSilent.pending, state => {
        state.fetchAssertionDetailsSilentStatus = 'loading';
      })
      .addCase(fetchAssertionDetailsSilent.fulfilled, (state, action) => {
        state.fetchAssertionDetailsSilentStatus = 'succeeded';
      })
      .addCase(fetchAssertionDetailsSilent.rejected, state => {
        state.fetchAssertionDetailsSilentStatus = 'failed';
      })
      .addCase(patchAssertionDetails.pending, state => {
        state.patchAssertionDetailsStatus = 'loading';
      })
      .addCase(patchAssertionDetails.fulfilled, (state, action) => {
        state.patchAssertionDetailsStatus = 'succeeded';
      })
      .addCase(patchAssertionDetails.rejected, state => {
        state.patchAssertionDetailsStatus = 'failed';
      })
      .addCase(postAssertionRun.pending, state => {
        state.postAssertionRunStatus = 'loading';
      })
      .addCase(postAssertionRun.fulfilled, (state, action) => {
        state.postAssertionRunStatus = 'succeeded';
      })
      .addCase(postAssertionRun.rejected, state => {
        state.postAssertionRunStatus = 'failed';
      })
      .addCase(patchAssertionResultInclude.pending, state => {
        state.patchAssertionResultIncludeStatus = 'loading';
      })
      .addCase(patchAssertionResultInclude.fulfilled, (state, action) => {
        state.patchAssertionResultIncludeStatus = 'succeeded';
      })
      .addCase(patchAssertionResultInclude.rejected, state => {
        state.patchAssertionResultIncludeStatus = 'failed';
      })
      .addCase(patchAssertionResultExclude.pending, state => {
        state.patchAssertionResultExcludeStatus = 'loading';
      })
      .addCase(patchAssertionResultExclude.fulfilled, (state, action) => {
        state.patchAssertionResultExcludeStatus = 'succeeded';
      })
      .addCase(patchAssertionResultExclude.rejected, state => {
        state.patchAssertionResultExcludeStatus = 'failed';
      })
      .addCase(postAssertionReview.pending, state => {
        state.postAssertionReviewStatus = 'loading';
      })
      .addCase(postAssertionReview.fulfilled, (state, action) => {
        state.postAssertionReviewStatus = 'succeeded';
      })
      .addCase(postAssertionReview.rejected, state => {
        state.postAssertionReviewStatus = 'failed';
      })
      .addCase(postFrameworkEvidenceExport.pending, state => {
        state.postFrameworkEvidenceExportStatus = 'loading';
      })
      .addCase(postFrameworkEvidenceExport.fulfilled, (state, _action) => {
        state.postFrameworkEvidenceExportStatus = 'succeeded';
      })
      .addCase(postFrameworkEvidenceExport.rejected, state => {
        state.postFrameworkEvidenceExportStatus = 'failed';
      })
      .addCase(fetchEvidence.pending, state => {
        state.fetchEvidenceStatus = 'loading';
      })
      .addCase(fetchEvidence.fulfilled, (state, action) => {
        state.fetchEvidenceStatus = 'succeeded';
        state.evidence = action.payload;
      })
      .addCase(fetchEvidence.rejected, state => {
        state.fetchEvidenceStatus = 'failed';
      })
      .addCase(fetchComplianceControls.pending, state => {
        state.fetchControlsStatus = 'loading';
      })
      .addCase(fetchComplianceControls.fulfilled, (state, action) => {
        state.fetchControlsStatus = 'succeeded';

        state.controls = action.payload;
        state.selectedControlId = action.payload
          ? action.payload[0]?.id
          : undefined;
      })
      .addCase(fetchComplianceControls.rejected, state => {
        state.fetchControlsStatus = 'failed';
      })
      .addCase(removeVendorRequirement.pending, state => {
        state.removeVendorRequirementStatus = 'loading';
      })
      .addCase(removeVendorRequirement.fulfilled, (state, action) => {
        state.removeVendorRequirementStatus = 'succeeded';
        state.frameworkRequirementCategories.forEach(category => {
          category.frameworkRequirements.forEach(requirement => {
            if (requirement.vendorFrameworkRequirementId == action.payload) {
              requirement.vendorFrameworkRequirementId = null;
              requirement.status =
                FrameworkRequirementExtendedStatusEnum.Pending;
            }
          });
        });
      })
      .addCase(removeVendorRequirement.rejected, state => {
        state.removeVendorRequirementStatus = 'failed';
      });
  },
});
export const {
  clearToastMessage,
  createControlRequest,
  createControlSuccess,
  createControlError,
  fetchAssignableComplianceTasksRequest,
  fetchAssignableComplianceTasksSuccess,
  fetchAssignableComplianceTasksError,
  fetchCategoriesRequest,
  fetchCategoriesSuccess,
  fetchCategoriesError,
  fetchFrameworkPoliciesRequest,
  fetchFrameworkPoliciesSuccess,
  fetchFrameworkPoliciesError,
  fetchFrameworksRequest,
  fetchFrameworksSuccess,
  fetchFrameworksError,
  fetchTaskDetailsRequest,
  fetchTaskDetailsSuccess,
  fetchTaskDetailsError,
  fetchTasksRequest,
  fetchTasksSuccess,
  fetchControlExtendedSuccess,
  fetchTasksError,
  fetchRequirementsRequest,
  fetchRequirementsSuccess,
  fetchFrameworkGuidanceSuccess,
  createVendorRequirementSuccess,
  postTaskRequest,
  postTaskSuccess,
  postTaskError,
  patchTaskRequest,
  patchTaskSuccess,
  patchTaskError,
  patchControlRequest,
  patchControlSuccess,
  patchControlError,
  setControlFormModel,
  setControlFormModelValid,
  setSelectedCategoryId,
  setSelectedControlId,
  setSelectedTaskId,
  setSelectedFramework,
  setShowControlDrawer,
  fetchNotApplicableControlsError,
  fetchNotApplicableControlsRequest,
  fetchNotApplicableControlsSuccess,
  setTasksFilters,
  setCategoriesFilters,
  setShowTaskDrawer,
  cleanupTaskDetails,
  postTaskEvidenceRequest,
  postTaskEvidenceSuccess,
  postTaskEvidenceError,
  fetchEvidenceDownloadDetailsRequest,
  fetchEvidenceDownloadDetailsSuccess,
  fetchEvidenceDownloadDetailsError,
  deleteEvidenceRequest,
  deleteEvidenceSuccess,
  deleteEvidenceError,
  removeApplicableControl,
  removeNotApplicableControl,
  markControlApplicableRequest,
  markControlApplicableFailure,
  markControlApplicableSuccess,
  markControlNotApplicableFailure,
  markControlNotApplicableRequest,
  markControlNotApplicableSuccess,
  addNotApplicableControl,
  addApplicableControl,
  setEditControlMode,
  createPDFReportRequest,
  createPDFReportSuccess,
  createPDFReportError,
  setTempTask,
  removeControlRelation,
  postFrameworkRequest,
  postFrameworkSuccess,
  postFrameworkError,
  setEditTaskMode,
  setTempTaskInitialFieldsRequest,
  setControlsFilterAssigneeId,
  setControlsFilterFrameworkIdentifier,
  setControlsFilterStatus,
  setControlsFilterApplicable,
  setControlsSearch,
  updateTasksInTheEditControlFormSlice,
  isPostingReviewHistoryItemRequest,
  isPostingReviewHistoryItemSuccess,
  isPostingReviewHistoryItemFailure,
  updateControlReviewStatusSuccess,
  isFetchingReviewHistoryItemsRequest,
  isFetchingReviewHistoryItemsSuccess,
  isFetchingReviewHistoryItemsFailure,
  updateTaskInTheTree,
  updateHiddenEditTaskDrawerControls,
  fetchControlExtendedRequest,
  fetchControlExtendedError,
  setDrawerOnTop,
  setSelectedFrameworkId,
  cleanupCompliance,
} = complianceSlice.actions;

export default complianceSlice.reducer;
