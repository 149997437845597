import { faCheckSquare } from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { formatShortDate } from '../../../functions/formatShortDate';
import {
  getPdfDocumentPath,
  PdfPreviewEntityPaths,
} from '../../../functions/routePathsHelpers';
import { AgreementSignatoryModelStatusEnum } from '../../../swagger';
import {
  AgreementModel,
  AgreementModelStatusEnum,
} from '../../../swagger/models/AgreementModel';
import { PdfOpenMode } from '../../../types/pdfOpenMode';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import AdoptechOverflowLine from '../../AdoptechOverflowLine/AdoptechOverflowLine';
import { AgreementMeatballMenu } from '../AgreementMeatballMenu/AgreementMeatballMenu';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { AgreementsMode } from './AgreementsList';
import { agreementShowPage } from '../../Routes/Routes';
import { AdoptechTooltip } from '../../AdoptechTooltip/AdoptechTooltip';

export interface RowProps {
  mode: AgreementsMode;
  handleDeleteClick: (id: AgreementModel['id']) => void;
  showStatusColumn: boolean;
  showReviewDateColumn: boolean;
  agreement: AgreementModel;
  baseCss: string;
  baseTableCss: string;
}

export const AgreementsRow: React.FC<RowProps> = ({
  mode,
  agreement,
  baseCss,
  baseTableCss,
  handleDeleteClick,
  showReviewDateColumn,
  showStatusColumn,
}) => {
  const dispatch = useDispatch();
  const handleRowClick = () => {
    dispatch(push(agreementShowPage.replace(':id', agreement.id)));
    return;
  };

  const isRejected = agreement.status === AgreementModelStatusEnum.Rejected;

  const isOverdue =
    agreement.reviewDate && moment(agreement.reviewDate).isBefore(new Date());

  return (
    <div
      onClick={() => handleRowClick()}
      className={
        baseTableCss +
        '--row ' +
        baseTableCss +
        '--slim' +
        (isRejected || isOverdue ? ' ' + baseTableCss + '--alert' : '')
      }
    >
      <AdoptechOverflowLine className={baseCss + '--name'}>
        {`${agreement.name} ${agreement.version}`}
      </AdoptechOverflowLine>
      <AdoptechOverflowLine className={baseCss + '--counterparty'}>
        {agreement?.externalSignatoriesNames[0] || ''}
      </AdoptechOverflowLine>
      <AdoptechOverflowLine className={baseCss + '--owner'}>
        <UserAvatar user={agreement.owner} size="small" />
      </AdoptechOverflowLine>
      {mode === AgreementsMode.Draft && (
        <div className={baseCss + '--shared'}>
          {agreement.shared ? (
            <div className={baseCss + '--shared-badge'}>
              <AdoptechTooltip
                text="Agreement shared with the counterparty but they have not signed so it can still be edited."
                placement="top"
                showTooltip
                identifier={`shared-tooltip-${agreement.id}`}
              >
                <div>Shared</div>
              </AdoptechTooltip>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {mode === AgreementsMode.AwaitingSignature && (
        <AdoptechOverflowLine>
          {agreement.signatoriesStats.signed}/{agreement.signatoriesStats.total}
        </AdoptechOverflowLine>
      )}
      {showStatusColumn && mode === AgreementsMode.AwaitingSignature && (
        <div>
          {isRejected && (
            <div className={baseTableCss + '--red-color'}>
              <FontAwesomeIcon
                size="lg"
                icon={faExclamationCircle}
                className="mr-2"
              />
              <span className="font-weight-bold">Rejected</span>
            </div>
          )}
          {agreement.signatoriesStats.signed > 0 && !agreement.toBeSigned && (
            <div className={baseTableCss} style={{ opacity: 0.5 }}>
              <FontAwesomeIcon
                size="lg"
                icon={faCheckSquare}
                className="mr-2"
              />
              Signed
            </div>
          )}
          {agreement.toBeSigned && (
            <AdoptechButton
              icon={faFileCheck}
              extraClass="adoptechButton-small"
              onClick={e => {
                e.stopPropagation();
                dispatch(push(agreementShowPage.replace(':id', agreement.id)));
              }}
              variant={AdoptechButtonVariant.PrimaryTransparent}
            >
              Sign
            </AdoptechButton>
          )}
        </div>
      )}
      {showReviewDateColumn &&
        (isOverdue ? (
          <div className={baseTableCss + '--red-color'}>
            <FontAwesomeIcon size="lg" icon={faClock} className="mr-2" />
            <span className="font-weight-bold">Overdue</span>
          </div>
        ) : (
          <div>{formatShortDate(agreement.reviewDate) || '-'}</div>
        ))}
      {showStatusColumn &&
        mode === AgreementsMode.Completed &&
        agreement.signatoriesStats.signed > 0 &&
        (agreement.agreementSignatories || []).some(
          signatory =>
            signatory.status ===
              AgreementSignatoryModelStatusEnum.NotRequired ||
            signatory.status === AgreementSignatoryModelStatusEnum.SignedOffline
        ) && (
          <div style={{ opacity: 0.5, fontSize: '0.8rem' }}>
            <FontAwesomeIcon size="lg" icon={faCheckSquare} className="mr-2" />
            {agreement.agreementSignatories.some(
              signatory =>
                signatory.status ===
                AgreementSignatoryModelStatusEnum.NotRequired
            )
              ? 'Signature not required'
              : 'Signed offline'}
          </div>
        )}
      <div
        className={baseCss + '--actions ml-auto'}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <AgreementMeatballMenu
          agreement={agreement}
          handleDeleteClick={handleDeleteClick}
        />
      </div>
    </div>
  );
};
