/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BoardMember,
  BoardMemberFromJSON,
  BoardMemberFromJSONTyped,
  BoardMemberToJSON,
  Company,
  CompanyFromJSON,
  CompanyFromJSONTyped,
  CompanyToJSON,
  CompanyExtendedAllOf,
  CompanyExtendedAllOfFromJSON,
  CompanyExtendedAllOfFromJSONTyped,
  CompanyExtendedAllOfToJSON,
  CompanyIdentifierModel,
  CompanyIdentifierModelFromJSON,
  CompanyIdentifierModelFromJSONTyped,
  CompanyIdentifierModelToJSON,
  CompanySector,
  CompanySectorFromJSON,
  CompanySectorFromJSONTyped,
  CompanySectorToJSON,
  Office,
  OfficeFromJSON,
  OfficeFromJSONTyped,
  OfficeToJSON,
  OfficerRole,
  OfficerRoleFromJSON,
  OfficerRoleFromJSONTyped,
  OfficerRoleToJSON,
} from './';

/**
 *
 * @export
 * @interface CompanyExtended
 */
export interface CompanyExtended extends Company {
  /**
   *
   * @type {boolean}
   * @memberof CompanyExtended
   */
  preferTradingName?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CompanyExtended
   */
  brandingColor?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyExtended
   */
  workforceModel?: CompanyExtendedWorkforceModelEnum;
  /**
   *
   * @type {string}
   * @memberof CompanyExtended
   */
  businessSummary?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CompanyExtended
   */
  visiblePolicyFrameworkReferences?: Array<CompanyExtendedVisiblePolicyFrameworkReferencesEnum>;
  /**
   *
   * @type {string}
   * @memberof CompanyExtended
   */
  legalEntityIdentifier?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyExtended
   */
  currencyCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyExtended
   */
  financialEntityType?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyExtended
   */
  financialEntityName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyExtended
   */
  competentAuthority?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyExtended
   */
  competentAuthorityName?: string | null;
}

export function CompanyExtendedFromJSON(json: any): CompanyExtended {
  return CompanyExtendedFromJSONTyped(json, false);
}

export function CompanyExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...CompanyFromJSONTyped(json, ignoreDiscriminator),
    preferTradingName: !exists(json, 'prefer_trading_name')
      ? undefined
      : json['prefer_trading_name'],
    brandingColor: !exists(json, 'branding_color')
      ? undefined
      : json['branding_color'],
    workforceModel: !exists(json, 'workforce_model')
      ? undefined
      : json['workforce_model'],
    businessSummary: !exists(json, 'business_summary')
      ? undefined
      : json['business_summary'],
    visiblePolicyFrameworkReferences: !exists(
      json,
      'visible_policy_framework_references'
    )
      ? undefined
      : json['visible_policy_framework_references'],
    legalEntityIdentifier: !exists(json, 'legal_entity_identifier')
      ? undefined
      : json['legal_entity_identifier'],
    currencyCode: !exists(json, 'currency_code')
      ? undefined
      : json['currency_code'],
    financialEntityType: !exists(json, 'financial_entity_type')
      ? undefined
      : json['financial_entity_type'],
    financialEntityName: !exists(json, 'financial_entity_name')
      ? undefined
      : json['financial_entity_name'],
    competentAuthority: !exists(json, 'competent_authority')
      ? undefined
      : json['competent_authority'],
    competentAuthorityName: !exists(json, 'competent_authority_name')
      ? undefined
      : json['competent_authority_name'],
  };
}

export function CompanyExtendedToJSON(value?: CompanyExtended | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...CompanyToJSON(value),
    prefer_trading_name: value.preferTradingName,
    branding_color: value.brandingColor,
    workforce_model: value.workforceModel,
    business_summary: value.businessSummary,
    visible_policy_framework_references: value.visiblePolicyFrameworkReferences,
    legal_entity_identifier: value.legalEntityIdentifier,
    currency_code: value.currencyCode,
    financial_entity_type: value.financialEntityType,
    financial_entity_name: value.financialEntityName,
    competent_authority: value.competentAuthority,
    competent_authority_name: value.competentAuthorityName,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CompanyExtendedWorkforceModelEnum {
  Distributed = 'distributed',
  Hybrid = 'hybrid',
  Office = 'office',
}
/**
 * @export
 * @enum {string}
 */
export enum CompanyExtendedVisiblePolicyFrameworkReferencesEnum {
  All = 'all',
  Iso27001 = 'iso_27001',
  Iso270012022 = 'iso_27001_2022',
  Soc2 = 'soc_2',
  CyberEssentials = 'cyber_essentials',
}
